/*
import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import './About.css';
import Slide from '../components/Slide';

const Presentation: React.FC = () => {
  
  return (
    <section className="about">
      <div className="container">
        {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> }
        <Slide isFullPresentation={true} />
        
      </div>
    </section>
  );
};

export default Presentation;
*/
import React from 'react';
import Layout from '../components/Layout';
import './About.css';

const Presentation: React.FC = () => {
  return (
     <section className="about">
      <div style={{ position: 'absolute', top: 50, left: 0, width: '100%', height: '100%' }}>
        {/* <h1>Full Presentation</h1> */}
        <iframe 
          src="/output_html/Nano Vision Systems_Pitch Deck_Design_v3_compressed.pdf" 
          width="100%" 
          height="800px" 
          style={{ border: 'none' }}
          title="Presentation PDF"
        />
      </div>
     </section>
  );
};

export default Presentation;